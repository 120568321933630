<template>
  <a-table bordered :dataSource="list" :pagination="false">
    <a-table-column title="专业">
      <template slot-scope="text">
        <span>{{ findMajor(text.major) }}</span>
      </template>
    </a-table-column>
    <a-table-column title="子专业">
      <template slot-scope="text">
        <span>{{ findSubMajor(text.major, text.majorSub) }}</span>
      </template>
    </a-table-column>
    <a-table-column title="规模类型">
      <template slot-scope="text">
        <span>{{ findSize(text.major, text.majorSub, text.scale) }}</span>
      </template>
    </a-table-column>
  </a-table>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    majorList() {
      return this.findDataDict("contract_scale");
    },
  },

  methods: {
    findMajor(value) {
      const obj = this.majorList.find((item) => item.value === value);
      return obj ? obj.name : "";
    },
    findSubMajor(major, value) {
      let name = "";
      const majorObj = this.majorList.find((item) => item.value === major);
      if (majorObj) {
        if (majorObj && majorObj.children) {
          const subMajorObj = majorObj.children.find(
            (item) => item.value === value
          );
          if (subMajorObj) {
            name = subMajorObj.name;
          }
        }
      }

      return name;
    },
    findSize(major, subMajor, value) {
      let name = "";
      const majorObj = this.majorList.find((item) => item.value === major);
      if (majorObj) {
        if (majorObj && majorObj.children) {
          const subMajorObj = majorObj.children.find(
            (item) => item.value === subMajor
          );
          if (subMajorObj && subMajorObj.children) {
            const sizeObj = subMajorObj.children.find(
              (item) => item.value === value
            );
            if (sizeObj) {
              name = sizeObj.name;
            }
          }
        }
      }

      return name;
    },
  },
};
</script>