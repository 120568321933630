<template>
  <div>
    <Pane :showRemarks="false" :bottom="0">
      <a-tabs v-model="active" :tabBarStyle="{
                margin: '0',
              }">
        <a-tab-pane key="info" tab="基本信息"></a-tab-pane>
        <a-tab-pane key="subpackage" tab="分包"></a-tab-pane>
      </a-tabs>
    </Pane>

    <div class="container">
      <div class="content" v-show="active === 'info'">
        <a-descriptions bordered :column="2" size="small">
          <a-descriptions-item :span="1">
            <span slot="label">
              <div style="width: 80px">设计编号</div>
            </span>
            {{ detail.code }}
          </a-descriptions-item>
          <a-descriptions-item label="合同名称" :span="1">{{ detail.name }}</a-descriptions-item>
          <a-descriptions-item label="合同金额">
            <span style="color: #1890ff; font-size: 18px">
              <Money :money="detail.contractAmount" />
            </span>
            <span>万元</span>

            <a-dropdown v-if="detail.changeList && detail.changeList.length > 0">
              <a style="padding-left: 8px" href="#" @click="e => e.preventDefault()">变更记录</a>

              <a-card slot="overlay" style="padding: 16px">
                <a-table bordered :data-source="detail.changeList" :pagination="false">
                  <a-table-column title="变更时间" data-index="effectTime" align="center" />
                  <a-table-column title="变更后金额(万)" align="right">
                    <template slot-scope="text">
                      <Money :money="text.amountNew" />
                    </template>
                  </a-table-column>
                  <a-table-column title="变更前金额(万)" align="right">
                    <template slot-scope="text">
                      <Money :money="text.amountOld" />
                    </template>
                  </a-table-column>
                  <a-table-column title="变更金额(万)" align="right">
                    <template slot-scope="text">
                      <Money :money="text.contractAmountChange" />
                    </template>
                  </a-table-column>
                </a-table>
              </a-card>
            </a-dropdown>
          </a-descriptions-item>
          <a-descriptions-item label="所属区域">
            <div>
              <span>{{ detail.areaName }}</span>
              <span v-if="detail.subAreaName">/{{ detail.subAreaName }}</span>
              <span v-if="detail.l3AreaName">/{{ detail.l3AreaName }}</span>
            </div>
          </a-descriptions-item>
          <a-descriptions-item label="责任部门">{{ detail.productionUnitNameUnique }}</a-descriptions-item>
          <a-descriptions-item label="生产负责人">{{ detail.productionMasterName }}</a-descriptions-item>
          <a-descriptions-item label="合同类型">
            <DataDictFinder dictType="contractType" :dictValue="detail.type" />
          </a-descriptions-item>

          <a-descriptions-item label="阶段">
            <template v-if="detail.phase">
              <div>
                <DataDictFinder
                  dictType="phase"
                  v-for="item in detail.phase.split(',')"
                  :key="item"
                  :dictValue="item"
                />
              </div>
            </template>
          </a-descriptions-item>

          <a-descriptions-item label="业主方" :span="2">{{ detail.partyAname }}</a-descriptions-item>
          <a-descriptions-item label="附件" :span="2">
            <div class="link" v-for="item in fileList" :key="item">
              <a target="_blank" download :href="item" v-if="item.indexOf('http') !== -1">
                {{ uploadFileNameFormatter(item)
                }}
              </a>
              <a v-else @click.prevent="clickLink(item)">
                {{
                fileNameFormatter(item)
                }}
              </a>
            </div>
          </a-descriptions-item>
          <a-descriptions-item label="工程概况" :span="2">
            <div v-if="detail.scale">{{ detail.scale }}</div>
          </a-descriptions-item>
        </a-descriptions>

        <div class="title">合同收款项</div>

        <a-table bordered :data-source="planReceiptList" :pagination="false">
          <a-table-column title="收款进度编号" data-index="code" />
          <a-table-column title="收款进度名称" data-index="name" />

          <a-table-column title="收款比例" align="right">
            <template slot-scope="text">
              <div>
                <span>{{ text.ratio }}%</span>
              </div>
            </template>
          </a-table-column>

          <a-table-column title="收款金额(万元)" align="right">
            <template slot-scope="text">
              <Money :money="text.planReceipt" />
            </template>
          </a-table-column>

          <a-table-column title="进度确认" align="center">
            <template slot-scope="text">
              <span v-if="text.isFinished">
                <a-icon type="check" style="color: #1890ff; font-size: 18px" />
              </span>
            </template>
          </a-table-column>
        </a-table>

        <div class="title">合同规模</div>
        <ScaleList :list="contractScaleList" />

        <div class="center">
          <a-space>
            <a-button type="primary" @click="next">下一步</a-button>
            <a-button @click="close">关闭</a-button>
          </a-space>
        </div>

        <a-form :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" :colon="false">
          <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 18, offset: 4 }">
            <ExtraInfo />
          </a-form-item>
        </a-form>
      </div>

      <div class="content" v-show="active === 'subpackage'">
        <SubPackage
          :necessaryList="necessaryList"
          :list="list"
          :otherList="otherList"
          :readOnly="true"
        />

        <div class="center">
          <a-button @click="close">关闭</a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import moment from "moment";
import { fetchDetail } from "@/api/contract";
// import { saveAs } from "file-saver";
import { encode } from "js-base64";
import SubPackage from "./components/subpackage";
import ExtraInfo from "./components/extra-info";

import ScaleList from "./components/scale-list";

export default {
  mixins: [watermark],
  components: {
    SubPackage,
    ExtraInfo,
    ScaleList
  },

  data() {
    return {
      active: "info",
      form: this.$form.createForm(this),
      detail: {}, // 从接口获取到的合同详情
      id: null,
      signStatus: null,
      fileList: [],
      selectedFileList: [],

      planReceiptList: [], // 合同收款项

      writtenValue: {}, // 暂存用户填写的表单

      necessaryList: [], // 必要分包
      list: [], // 非必要
      otherList: [],

      org: [], // 组织列表
      searchResult: [], // 查询结果集

      charger: {}, // 生产负责人

      contractScaleList: [] // 合同规模列表
    };
  },

  computed: {
    showPhaseList() {
      return this.phaseList.filter(item => item.value === "FA");
    },

    disabled1() {
      // 如果是在审核中，就禁用
      return this.detail.contractSignStatus === "approved";
    },
    disabled2() {
      // 如果审核成功，也禁用保存按钮
      return this.detail.contractSignStatus === "reviewing";
    }
  },

  mounted() {
    const { query } = this.$route;
    const { id, active } = query || {};

    if (active) {
      this.active = active;
    }

    if (id && this.id !== id) {
      this.id = id;

      fetchDetail(id).then(res => {
        this.detail = Object.freeze(res);

        if (res.attachments) {
          this.fileList = res.attachments.split(",");
        }

        if (Array.isArray(res.planReceiptList)) {
          this.planReceiptList = res.planReceiptList;
        }

        if (Array.isArray(res.contractScaleList)) {
          this.contractScaleList = res.contractScaleList;
        }

        if (Array.isArray(res.projects) && res.projects.length > 0) {
          const item = res.projects[0];
          if (Array.isArray(item.subPackages)) {
            this.necessaryList = item.subPackages
              .filter(element => element.isneeded === 1)
              .map(item => {
                return {
                  ...item,
                  planAmount: item.planAmount / 10000,
                  fileList: item.attachments
                    ? item.attachments.split(",").map(item => {
                        return {
                          name: this.$getFileName(item),
                          completePath: item
                        };
                      })
                    : undefined
                };
              });
            this.list = item.subPackages
              .filter(element => element.isneeded === 0)
              .map(item => {
                return {
                  ...item,
                  planAmount: item.planAmount / 10000,
                  fileList: item.attachments
                    ? item.attachments.split(",").map(item => {
                        return {
                          name: this.$getFileName(item),
                          completePath: item
                        };
                      })
                    : undefined
                };
              });
            this.otherList = item.subPackages
              .filter(element => element.isneeded === 2)
              .map(item => {
                return {
                  ...item,
                  planAmount: item.planAmount / 10000,
                  fileList: item.attachments
                    ? item.attachments.split(",").map(item => {
                        return {
                          name: this.$getFileName(item),
                          completePath: item
                        };
                      })
                    : undefined
                };
              });
          }
        }
      });
    }
  },

  methods: {
    next() {
      this.active = "subpackage";
    },

    clickLink(path) {
      const num = path.split("_")[0];
      const time = moment().format("YYYY-MM-DD HH:mm:ss");
      const auth = encode(num + "_" + time);
      const url = `http://218.94.135.30:8025/FileStore/Download.aspx?auth=${auth}`;

      window.open(url)
      const that = this;
      this.$info({
        title: '如果浏览器无法下载，请手动复制下方文件链接',
        content: url,
        okText: '复制',
        onOk() {
          that.$copy(url)
        }
      })

      // const newWindow = window.open("_blank");
      // newWindow.location = url;
      // const hide = this.$message.loading("下载中...");
      // downloadFile({
      //   fileUrl: url
      // })
      //   .then(blob => {
      //     saveAs(blob, this.fileNameFormatter(path));
      //   })
      //   .finaly(() => {
      //     hide();
      //   });

      // const that = this;
      // navigator.clipboard.writeText(url).then(
      //   function () {
      //     that.$success({
      //       title: "提示",
      //       content: "复制链接成功！",
      //     });
      //   },
      //   function () {
      //     that.$error({
      //       title: "提示",
      //       content: "复制链接失败！",
      //     });
      //   }
      // );

      // window.open(url);
    },

    handleSignStatusChange(e) {
      const { value } = e.target;
      this.signStatus = value;
      // 未签订时，选中方案
      if (value === "unsigned") {
        this.form.setFieldsValue({
          phase: ["FA"]
        });
      } else {
        this.form.setFieldsValue({
          phase: []
        });
      }
    },

    // 金慧的文件名称格式化
    fileNameFormatter(path) {
      const arr = path.split("_");
      if (arr.length >= 2) {
        return arr[1];
      } else {
        return path;
      }
    },
    // 自己上传的文件名称格式化
    uploadFileNameFormatter(path) {
      const arr = path.split("_");
      if (arr.length > 0) {
        return arr[arr.length - 1];
      } else {
        return path;
      }
    },

    moneyFilter(money) {
      let startIndex = 0;
      if (parseFloat(money) < 0) {
        startIndex = 1;
      }

      let str = String(money)
        .substring(startIndex)
        .split("")
        .reverse()
        .join("");

      let val = str
        .split("")
        .map((item, index) => {
          if (index + 1 === str.length) {
            return item;
          }
          if ((index + 1) % 3 === 0) {
            return item + ",";
          } else {
            return item;
          }
        })
        .join("")
        .split(",")
        .map(item => {
          return item
            .split("")
            .reverse()
            .join("");
        })
        .reverse()
        .join(",");

      if (startIndex === 1) {
        return "-" + val;
      } else {
        return val;
      }
    },

    dateFormatter(date) {
      const dateStr = String(date);
      if (dateStr.length === 6) {
        return dateStr.substring(0, 4) + "-" + dateStr.substring(4);
      } else {
        return date;
      }
    },

    close() {
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 160px;
}

.link {
  margin-bottom: 4px;
}

.title {
  font-weight: bold;
  font-size: 15px;
  margin-top: 24px;
  margin-bottom: 16px;
}

.center {
  margin-top: 80px;
}

.extra {
  padding-top: 48px;
  line-height: 2em;

  .title {
    font-weight: bold;
    font-size: 13px;
  }
}
</style>
